import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Povix Cleaning Co.
			</title>
			<meta name={"description"} content={"Где чистота сочетается с безмятежностью - Povix, ваш проект для чистого пространства"} />
			<meta property={"og:title"} content={"Povix Cleaning Co."} />
			<meta property={"og:description"} content={"Где чистота сочетается с безмятежностью - Povix, ваш проект для чистого пространства"} />
			<meta property={"og:image"} content={"https://povixvoom.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://povixvoom.com/img/3965825.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://povixvoom.com/img/3965825.png"} />
			<meta name={"msapplication-TileImage"} content={"https://povixvoom.com/img/3965825.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://povixvoom.com/img/1.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://povixvoom.com/img/2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-width="100%"
				padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					text-transform="uppercase"
					letter-spacing="2px"
					sm-text-align="left"
				>
					Клининг
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Povix Cleaning Co.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Компания Povix специализируется на превращении жилых и рабочих помещений в бастионы чистоты и спокойствия. Наша задача - обеспечить тщательную уборку квартир, таунхаусов, коттеджей и офисов, чтобы каждый уголок сиял чистотой. Позвольте нам помочь вам перестроить свое окружение, создав свободное от беспорядка, безупречное и спокойное место, в котором вы будете процветать.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-7">
			<Text margin="0px 0px 50px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
			Почему стоит выбрать Povix Cleaning Co?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="0 40px"
				md-grid-template-columns="1fr"
				md-grid-gap="50px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
							Индивидуальные решения
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Мы понимаем, что каждое помещение уникально. Именно поэтому мы предлагаем индивидуальные планы уборки, разработанные с учетом ваших конкретных требований, обеспечивая оптимальную чистоту и организацию.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
							Экологически безопасная уборка
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								В Povix мы заботимся о защите окружающей среды. Наш выбор экологичных чистящих средств и методов обеспечивает безопасное, не содержащее химикатов пространство для вас и для Земли.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
							Профессиональная и увлеченная команда
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Наши уборщики - это сердце нашего сервиса. Каждый из них выбирается за свой опыт, внимание к деталям и искреннюю страсть к преобразованию помещений в более чистую и счастливую среду.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
							Гарантированное удовлетворение
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Ваше спокойствие - наш главный приоритет. Мы полностью застрахованы, и на нашу работу предоставляется гарантия удовлетворения, гарантирующая, что мы всегда оправдываем ваши ожидания.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://povixvoom.com/img/3.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://povixvoom.com/img/4.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Отправляйтесь в путешествие за чистотой вместе с Povix Cleaning Co. Наша преданная команда делает все возможное, применяя тщательный подход, чтобы изгнать грязь и беспорядок из каждого уголка вашего помещения. Мы готовы удовлетворить ваши разнообразные потребности, предлагая индивидуальную уборку, которая говорит о заботе и аккуратности.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://povixvoom.com/img/5.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Povix Cleaning Co. - Переустройство пространства, обновление духа
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});